import React, { useState, useEffect } from 'react'
import { BrowserRouter, Link } from 'react-router-dom'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loader from './Loader';
const cookies = new Cookies();
var CAIA = "CAIA";

function Riskmanagement(props) {

    const [refresh, setrefresh] = useState(true);
    const [cricketList, setcricketList] = useState([]);
    const [reload, setreload] = useState(true);
    const [Sporttype, setSporttype] = useState(1)
    const [tennisList, settennisList] = useState([]);
    const [sportname, setsportname] = useState("Cricket");

    useEffect(() => {

        var ssid = cookies.get('sid');
        if (!ssid) return;
        setrefresh(true);

        axios.post('https://flagapi.in/manageapi/riskManagementList', {
            sid: ssid,
            eventType: Sporttype
        })

            .then(result => {

                if (result.status === 200) {
                    setrefresh(false);
                    var res = [];

                    for (let key in result.data) {

                        if (result.data.hasOwnProperty(key)) {
                            result.data[key].eventId = key;
                            res.push(result.data[key]);

                        }
                    }
                    settennisList(res);
                }
            }
            ).catch(e => { });
    }, [Sporttype, reload])



    const function_sportType = (type) => {
        setSporttype(type);
        if (type == "4") setsportname("Cricket")
        if (type == "1") setsportname("Soccer")
        if (type == "2") setsportname("Tennis")
    }



    return (
        <React.Fragment>


            {refresh &&
                <Loader />
            }
            <body class="biab_fluid_body biab_desktop">
                <div class="biab_body biab_fluid biab_account-page" id="biab_body">
                    <div class="biab_wrapper js-wrapper" style={{ width: "1347px" }}>
                        <div id="biab_message-dialog" class="biab_message-dialog-wrapper"></div>
                        <div className="biab_table-tabs js-table-tabs">
                            <ul className="path" style={{ display: 'flex', height: '27px', width: '232px' }}>
                                {/* <li className="tab_active" onClick={()=>{function_sportType(4)}}  className={`${(Sporttype === 4) ? "tab_active" : "tab"}`}> <a style={{ marginLeft: ' -14px' }} >Cricket</a></li> */}
                                <li><a onClick={() => { function_sportType(1) }} className={`${(Sporttype === 1) ? "tab_active" : "tab"}`}>Soccer</a></li>
                                <li><a onClick={() => { function_sportType(2) }} className={`${(Sporttype === 2) ? "tab_active" : "tab"}`}>Tennis</a></li>
                                <li><a onClick={() => { function_sportType(7522) }} className={`${(Sporttype === 7522) ? "tab_active" : "tab"}`}>BasketBall</a></li>
                            </ul>
                        </div>
                        <div class="biab_page-wrapper">
                            <div class="biab_page-holder" style={{ margin: "-28px 0px -46px" }}>
                                <div class="biab_page-container">
                                    <div className="js-scroll-start" style={{ margin: "28px" }} />
                                    <div className="biab_scrollable js-scrollable" style={{ position: 'relative', overflow: 'hidden' }}>
                                        <div className="biab_scrollable-content js-scrollable-content" style={{ marginBottom: '107px' }}>

                                            <div style={{ marginBottom: '20px', marginLeft: '0px' }}>
                                                <div style={{ height: '30px', width: '100%', backgroundColor: '#0074b1', color: 'white', fontSize: '15px', fontWeight: 'bold', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '10px' }}> Market</div>
                                                <div>
                                                    <table class="tableReverse">
                                                        <tr class="Td_Reverse">
                                                            <th class="Td_Reverse" style={{ width: '10%', textAlign: 'center' }}>DateTime</th>
                                                            <th class="Td_Reverse" style={{ width: '10%', textAlign: 'center' }}>Event Id</th>
                                                            <th class="Td_Reverse" style={{ width: '35%', textAlign: 'center' }}>Event Name</th>
                                                            <th class="Td_Reverse" style={{ width: '15%', textAlign: 'center' }}>1 </th>
                                                            <th class="Td_Reverse" style={{ width: '15%', textAlign: 'center' }}>X</th>
                                                            <th class="Td_Reverse" style={{ width: '15%', fontSize: '24px', textAlign: 'center' }}>2</th>
                                                        </tr>
                                                        {tennisList.map((item2, id) => {
                                                            return (
                                                                <tr key={id} >
                                                                    <td class="Td_Reverse" style={{ width: '10%', textAlign: 'center' }}>{item2.date}</td>
                                                                    <td class="Td_Reverse" style={{ width: '10%', textAlign: 'center', backgroundColor: `${(moment() >= moment(item2.date)) ? "#86BA00" : "whitesmoke"}` }}>{item2.eventId}</td>
                                                                    <td class="Td_Reverse" style={{ width: '35%', textAlign: 'center' }}>{item2.eventName}</td>
                                                                    <td class="Td_Reverse" style={{ width: '15%', textAlign: 'center', color: `${item2.teamA_total < 0 ? "red" : "green"}` }}>{parseFloat(item2.teamA_total).toFixed(2)}</td>
                                                                    <td class="Td_Reverse" style={{ width: '15%', textAlign: 'center', color: `${item2.draw_total < 0 ? "red" : "green"}` }}> {parseFloat(item2.draw_total).toFixed(2)}</td>
                                                                    <td class="Td_Reverse" style={{ width: '15%', textAlign: 'center', color: `${item2.teamB_total < 0 ? "red" : "green"}` }}>{parseFloat(item2.teamB_total).toFixed(2)}
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })}

                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>


        </React.Fragment>
    )
}

export default Riskmanagement
