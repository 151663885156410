import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { toast } from 'react-toastify'
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import Loader from './Loader';
import VoidPopup from './VoidPopup';
import Cookies from 'universal-cookie';
import Pagination from 'react-js-pagination';


const cookies = new Cookies();

const url = window.location.href;
const para = url.split('/');
const event_Id = para[4];
const market_ID = para[5];

window.down_day = 3;
var sportname;
var totalPLvalue = 0;
toast.configure()

export const DataAnalysis = () => {
    const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
    const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
    const [startDate, setStartDate] = useState(moment().toDate());
    const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
    const [refresh, setrefresh] = useState(false);

    const [lenrecord, setlenrecord] = useState(0)
    const [betmonitorData, setbetmonitorData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(100);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = betmonitorData.slice(indexOfFirstPost, indexOfLastPost);
    const [update, setupdate] = useState(false)
    const [AgentLevel, setAgentLevel] = useState(" ");
    const [eventMarket, seteventMarket] = useState("Match Odds");
    const [orderBy, setorderBy] = useState("Ascending");
    const [stakeamount, setstakeamount] = useState("0");
    const [agentUser, setagentUser] = useState(" ");
    const [ProfitTeam, setProfitTeam] = useState(" ");
    const [betStatus, setbetStatus] = useState("Matched");
    const [StartTime, setStartTime] = useState("00:00:00");
    const [EndTime, setEndTime] = useState("23:59:59");
    const [betdata, setbetdata] = useState([])
    const [VoidReason, setVoidReason] = useState(" ");
    const [Event_Name, setEvent_Name] = useState()
    const [runnername1, setrunnername1] = useState()
    const [fancylist, setfancylist] = useState([])
    const [MarketNameList, setMarketNameList] = useState([])
    const [runnername2, setrunnername2] = useState()
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [VoidPop, setVoidPop] = useState(false);



    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    };


    const handleVoid = (val) => {
        if (betdata.length > 0) {
            setVoidPop(val)
        } else if (VoidReason === " ") {
            toast.warn('Please select Void Reason !!', { position: toast.POSITION.TOP_CENTER })
            return;
        }
        else {
            toast.warn('Please select atleast one data !!', { position: toast.POSITION.TOP_CENTER })
            return;
        }
    };

    // const handleSelectAll = e => {
    //     setIsCheckAll(!isCheckAll);
    //     setbetdata(list.map(li => li.id));
    //     if (isCheckAll) {
    //       setbetdata([]);
    //     }
    //   };

    const storeBetData = () => {
        let arr = [];
        let checkboxes = document.querySelectorAll("input[type='checkbox']:checked");
        for (let i = 0; i < checkboxes.length; i++) {
            arr.push(checkboxes[i].value)
        }
        setbetdata(arr)
    }

    const changeEvent = (e) => {
        var selectBox = document.getElementById("user_level");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        setAgentLevel(selectedValue);
    }

    const changeMarket = (e) => {
        var selectBox = document.getElementById("changeMarket");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        seteventMarket(selectedValue);
    }

    const changeAsc = (e) => {
        var selectBox = document.getElementById("changeAsc");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        setorderBy(selectedValue);
    }

    const ChangeBetstatus = (e) => {
        var selectBox = document.getElementById("ChangeBetstatus");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        setbetStatus(selectedValue);
    }

    const changeProfitLoss = (e) => {
        var selectBox = document.getElementById("changeProfitLoss");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        setProfitTeam(selectedValue);

    }

    const VoidedReason = (e) => {
        var selectBox = document.getElementById("voidReason");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        setVoidReason(selectedValue);

    }
    const function_client_filter = (betType, sId) => {
        var start = '';
        var end = '';
        var marketId = ""
        var betT = ""
        if (window.down_day === 3) {
            start = sDate;
            end = eDate;
        }

        if (betType === "All") {
            marketId = ""
        }
        else if (betType === "Fancy") {
            marketId = sId
        }
        else if (betType === "Match Odds") {
            marketId = market_ID
        }
        if (betType == 1) betT = "match"
        if (betType == 2) betT = "Bookmaker"
        if (betType == 3) betT = "fancy"

        var ssid = cookies.get('sid');
        // setrefresh(true)
        axios.post('https://flagapi.in/manageapi/GetbetMonitoringNew', {
            sid: ssid,
            eventId: event_Id,
            StakeAmount: stakeamount,
            Userid: agentUser,
            MarketType: betType,
            UserLevel: AgentLevel,
            marketId: sId,
            OrderBy: orderBy,
            BetStatus: betStatus,
            startDate: start,
            startTime: StartTime,
            endDate: end,
            endTime: EndTime,
            WinningProfit: ProfitTeam

        }).then(result => {
            if (result.status == 200) {
                if (result.data.length == 0) {
                    toast.warn('You have no bets in this periods!', { position: toast.POSITION.TOP_CENTER })
                }
                setbetmonitorData(result.data)
            }
        }).catch(e => { });
        // setrefresh(false)
    }

    const function_bet_MatchOddsist = (marketId) => {

        function_client_filter("All", "")

        function_client_filter("Match Odds", marketId)

        function_client_filter("Over/Under 0.5 Goals", marketId)

        function_client_filter("Over/Under 1.5 Goals", marketId)

        function_client_filter("Over/Under 2.5 Goals", marketId)
    }

    const function_bet_fancylist = (s_id) => {
        document.getElementById("changeMarket").value = "Fancy"
        // setselection_id_shord("3",s_id);
        function_client_filter("Fancy", s_id)

        // var ssid = cookies.get('sid');
        // setrefresh(true)
        // axios.post('https://flagapi.in/manageapi/getbetmonitoring_betfancylist', {
        //     sid: ssid,
        //     eventId: event_Id,
        //     marketId: s_id
        // }).then(result => {
        //     if (result.status == 200) {
        //         setbetmonitoringdata(result.data)
        //         setlenrecord(result.data.length)
        //     }
        // }).catch(e => { });
        // setrefresh(false)

    }

    useEffect(() => {

        var ssid = cookies.get("sid");
        axios.post("https://flagapi.in/manageapi/OnlyEventName", {
            sid: ssid,
            eventId: event_Id,
            marketId: market_ID,
        })
            .then((result) => {

                if (result.status === 200) {
                    setEvent_Name(result.data[0].eventName);
                    setrunnername1(result.data[0].runnerName1)
                    setrunnername1(result.data[0].runnerName2)

                    if (result.data[0].sportId == 4) sportname = "Cricket"
                    else if (result.data[0].sportId == "1") sportname = "Soccer"
                    else if (result.data[0].sportId == 2) sportname = "Tennis"
                }

            })
            .catch((e) => { });



        axios.post("https://flagapi.in/manageapi/getbetmonitoring_fancylist", {
            sid: ssid,
            eventId: event_Id,
        }).then((result) => {

            if (result.status === 200) {
                setfancylist(result.data);

            }
        }).catch((e) => { });

        // marketName

        axios.post("https://flagapi.in/manageapi/getmarketId", {
            sid: ssid,
            eventId: event_Id,
        }).then((result) => {
            if (result.status === 200) {
                setMarketNameList(result.data);

            }
        }).catch((e) => { });

    }, [event_Id])

    const betVoid = () => {

        var ssid = cookies.get('sid');
        setrefresh(true)
        axios.post('https://flagapi.in/manageapi/Betvoid', {
            sid: ssid,
            betIds: betdata,
            pass: '1234',
            voidReason: VoidReason

        }).then(result => {
            if (result.status == 200) {
                setrefresh(false);
                handleVoid(false);
                setupdate(!update)
                toast.success('Bets void succesfull !!', { position: toast.POSITION.TOP_CENTER })
                // setTimeout(() => {
                //     window.location.reload()
                // }, 1000);
            }
        }).catch(e => { });

    }


    return (
        <React.Fragment>
            {VoidPop &&
                <VoidPopup handleVoid={handleVoid} betVoid={betVoid} />}

            <body class="biab_fluid_body biab_desktop">
                <div class="biab_body biab_fluid biab_account-page" id="biab_body">
                    <div class="biab_wrapper js-wrapper" style={{ width: "1347px" }}>
                        <div id="biab_message-dialog" class="biab_message-dialog-wrapper"></div>

                        <div className="biab_table-tabs js-table-tabs">
                            <ul className="path" style={{ display: 'flex', height: '27px' }}>
                                <li> <a style={{ marginLeft: "-17px" }} >{sportname}  </a></li>
                                <li style={{ marginTop: '-14px' }}> <a style={{ marginTop: '-8px' }} href="/">{Event_Name}<i class="fas fa-angle-right"></i> </a></li>
                                <li> </li>
                                <li> </li>
                                <li></li>
                            </ul>
                            <ul style={{ display: 'flex', height: '30px', width: "200px", marginLeft: '50px', marginTop: '20px' }}>
                                <li>
                                    <div style={{ height: '30px', position: 'absolute', zIndex: '99' }}>
                                        <p style={{ color: 'white', fontSize: '12px', marginTop: '6px', marginLeft: '-47px', position: 'absolute', zIndex: '99' }}>Market :</p>
                                        <select id="changeMarket" class="input_user_search " style={{ height: '30px', width: '100px', color: 'gray', fontSize: '12px', borderRadius: '4px' }} onChange={(e) => { function_bet_MatchOddsist(e.target.value) }}>
                                            {/* onChange = {(e)=>{changeMarket(e);}} */}
                                            <option value="All">Select Market</option>
                                            {MarketNameList.map((item2, id2) => {
                                                // setlenrecord(id2)
                                                //  lenrecord = id2
                                                return (
                                                    <option value={item2.marketId}>{item2.marketName}</option>
                                                )
                                            })}
                                            {/* <option value="Match Odds">Match Odds</option>
                                            <option value="Bookmaker">Bookmaker</option>
                                            <option value="Fancy">Fancy</option>
                                            <option value="Over/Under 0.5 Goals">Over/Under 0.5 Goals</option>
                                            <option value="Over/Under 1.5 Goals">Over/Under 1.5 Goals</option>
                                            <option value="Over/Under 2.5 Goals">Over/Under 2.5 Goals</option> */}


                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div style={{ height: '30px', position: 'absolute', zIndex: '99', marginLeft: '115px' }}>
                                        {/* <p style={{ color: 'white', fontSize: '12px', marginTop: '6px',marginLeft:'-47px', position: 'absolute', zIndex: '99' }}>Select Fancy</p> */}
                                        <select id="fancyname" class="input_user_search" style={{ height: '30px', width: '122px', color: 'gray', fontSize: '12px' }} onChange={(e) => { function_bet_fancylist(e.target.value) }} >
                                            <option value="0">Select Fancy List </option>
                                            {fancylist.map((item2, id2) => {
                                                return (
                                                    <option value={item2.selectionId}>{item2.runnerName}</option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                </li>



                                <li>
                                    <div style={{ height: '30px', position: 'absolute', zIndex: '99', marginLeft: '266px' }}>
                                        <p style={{ color: 'white', fontSize: '12px', marginTop: '6px', marginLeft: '-16px', position: 'absolute', zIndex: '99' }}>User Level :</p>
                                        <select id="user_level" class="input_user_search " style={{ height: '30px', width: '100px', color: 'gray', fontSize: '12px', borderRadius: '4px', marginLeft: '55px' }} onChange={(e) => { changeEvent(e); }} >
                                            <option value=" " selected>User Level</option>
                                            <option value="ADM Level">ADM Level</option>
                                            <option value="SA Level">SA Level</option>
                                            <option value="SS Level">SS Level</option>
                                            <option value="SUP Level">SUP Level</option>
                                            <option value="MA Level">MA Level</option>
                                            <option value="PL Level">PL Level</option>

                                        </select>
                                    </div>
                                </li>


                                <li>
                                    <div style={{ height: '30px', color: 'white', position: 'absolute', zIndex: '99', marginLeft: '505px' }}>
                                        <p style={{ fontSize: '12px', marginTop: '6px', marginLeft: '-74px', position: 'absolute', zIndex: '99' }}>User Name :</p>
                                        <input id="cl_id" class="input_user_search " onChange={(e) => { setagentUser(e.target.value); }} placeholder="Enter user" style={{ height: '27px', width: '100px', fontSize: '12px' }} />

                                        {/* <a style={{ marginLeft: '-20px' }} ><i class="fa fa-search"></i></a> */}
                                    </div>
                                </li>

                                <li>
                                    {/* <div style={{height: "30px", position: "absolute", zIndex: 99}}> */}
                                    <p style={{ color: 'white', fontSize: '12px', marginTop: '6px', marginLeft: '612px', position: 'absolute', zIndex: '99' }}>Stake :</p>
                                    <input name="sortCondition" id="sortCondition" placeholder="Enter Stake" onChange={(e) => { setstakeamount(e.target.value); }} style={{ marginLeft: 654, width: '100px', height: 30, borderRadius: '4px' }} />
                                    {/* </div> */}
                                </li>
                                <li>
                                    <p style={{ color: 'white', fontSize: '12px', marginTop: '6px', marginLeft: '10px', position: 'absolute', zIndex: '99' }}>Order By : </p>
                                    <select name="orderByClause" id="changeAsc" style={{ marginLeft: '70px', height: '30px', borderRadius: '4px' }} onChange={(e) => { changeAsc(e.target.value) }}>
                                        <option value="Ascending" >Ascending</option>
                                        <option value="Descending">Descending</option>
                                    </select></li>

                                <li>
                                    <p style={{ fontSize: '12px', color: 'white', marginTop: '6px', marginLeft: '10px', position: 'absolute', zIndex: '99' }}>Bet status : </p>
                                    <select name="orderByClause" id="ChangeBetstatus" style={{ marginLeft: '70px', height: '30px', borderRadius: '4px' }} onChange={(e) => { ChangeBetstatus(e.target.value); }}>
                                        <option value="ALL" >All</option>
                                        <option value="UnMatched">Unmatched</option>
                                        <option value="Matched" selected="Matched"> Matched</option>
                                        <option value="Voided">Voided</option>
                                        <option value="Cancelled">Cancelled</option>
                                    </select></li>

                                <li>
                                    <div style={{ height: '30px', position: 'absolute', zIndex: '99' }}>
                                        <p style={{ color: 'white', fontSize: '12px', marginTop: '6px', marginLeft: '13px', position: 'absolute', zIndex: '99' }}>Winning :</p>
                                        <select id="changeProfitLoss" class="input_user_search " style={{ height: '30px', width: '100px', color: 'gray', fontSize: '12px', borderRadius: '4px', marginLeft: '72px' }} onChange={(e) => { changeProfitLoss(e.target.value); }}>
                                            <option value="">Profit bets</option>
                                            <option value="TeamA">TeamA</option>
                                            <option value="TeamB">TeamB</option>
                                            <option value="Draw">Draw</option>
                                            {/* <option value="TeamA">fancy</option> */}

                                        </select>
                                    </div>
                                </li>




                            </ul>
                        </div>

                        {/* second tab */}

                        <div className="biab_table-tabs js-table-tabs" style={{ display: "flex", width: 'auto' }}>

                            <ul style={{ display: 'flex', height: '30px', width: "200px", marginLeft: '52px' }}>
                                <li>
                                    <div style={{ height: '30px', position: 'absolute', zIndex: '99', width: '100px' }}>
                                        <p style={{ color: 'white', fontSize: '12px', marginTop: '6px', marginLeft: '-47px', position: 'absolute', zIndex: '99' }}>Period :</p>
                                        <div class="input_user_search " >

                                            <DatePicker
                                                selectsStart
                                                dateFormat="yyyy-MM-dd"
                                                selected={startDate}
                                                //  minDate={NOW}
                                                placeholderText="YYYY-MM-DD"
                                                className="cal-input"
                                                onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
                                            />

                                        </div>
                                    </div>
                                </li>


                                <li>
                                    <div style={{ height: '30px', position: 'absolute', zIndex: '99', marginLeft: '106px', width: '80px' }}>
                                        {/* <p style={{ color: 'white', fontSize: '12px', marginTop: '6px',marginLeft:'-41px', position: 'absolute', zIndex: '99' }}>Time :</p> */}
                                        {/* <CTimePicker inputReadOnly locale="en-US" /> */}
                                        <div class="input_user_search">
                                            <input id="st_id" class="input_user_search " onChange={(e) => { setStartTime(e.target.value); }} placeholder="HH:MM:SS" style={{ height: '27px', width: '100px', fontSize: '12px' }} />
                                        </div>
                                    </div>
                                </li>


                                {/* to from  */}

                                <li>
                                    <div style={{ height: '30px', position: 'absolute', zIndex: '99', marginLeft: '220px', width: '100px' }}>
                                        <p style={{ color: 'white', fontSize: '12px', marginTop: '6px', marginLeft: '-28px', position: 'absolute', zIndex: '99' }}>To </p>
                                        <div class="input_user_search">
                                            <DatePicker
                                                selectsEnd
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="YYYY-MM-DD"
                                                selected={endDate}
                                                onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
                                                className="cal-input"
                                            /></div>
                                    </div>
                                </li>


                                <li>
                                    <div style={{ height: '30px', position: 'absolute', zIndex: '99', marginLeft: '326px', width: '80px' }}>
                                        {/* <p style={{ color: 'white', fontSize: '12px', marginTop: '6px',marginLeft:'-40px', position: 'absolute', zIndex: '99' }}>Time :</p> */}
                                        <div class="input_user_search">
                                            <input id="st_id" class="input_user_search " placeholder="HH:MM:SS" onChange={(e) => { setEndTime(e.target.value); }} style={{ height: '27px', width: '100px', fontSize: '12px' }} />
                                        </div>
                                    </div>
                                </li>




                                <li >
                                    <button type="button" class="biab_close js-close" data-dismiss="modal" aria-label="Close" style={{ marginLeft: "425px", height: "30px", width: "45px", fontFamily: "inherit", color: "#fff", borderRadius: "6px", backgroundColor: "#0074b1" }} onClick={() => { function_client_filter() }}>Get</button>
                                </li>

                            </ul>
                        </div>


                        {/* Third Tab */}

                        <div className="biab_table-tabs js-table-tabs" style={{ display: "flex", width: 'auto' }}>

                            <ul style={{ display: 'flex', height: '30px', width: "200px", marginLeft: '52px' }}>

                                <li>
                                    <div style={{ height: '30px', position: 'absolute', zIndex: '0' }}>
                                        <p style={{ color: 'white', fontSize: '12px', marginTop: '6px', marginLeft: '-47px', position: 'absolute', zIndex: '99' }}>Action :</p>
                                        <select id="betsStatus" class="input_user_search" style={{ height: '30px', width: '100px', color: 'gray', fontSize: '12px', borderRadius: '4px' }}  >
                                            <option value="0">Bet Status</option>
                                            <option value="1">Cancelled</option>
                                            <option value="2">Voided</option>
                                            {/* <option value="3">fancy</option> */}

                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div style={{ height: '30px', position: 'absolute', zIndex: '0' }}>
                                        <p style={{ color: 'white', fontSize: '12px', marginTop: '6px', marginLeft: '119px', position: 'absolute', zIndex: '99' }}></p>
                                        <select id="voidReason" class="input_user_search " style={{ height: '30px', width: '100px', color: 'gray', fontSize: '12px', borderRadius: '4px', position: 'absolute', left: '129px', top: '2px' }} onChange={(e) => { VoidedReason(e.target.value); }} >
                                            <option value="0">Reaasons</option>
                                            <option value="Back/Lay">Back/Lay</option>
                                            <option value="Cheat Bets">Cheat Bets</option>
                                            <option value="Wrong Rate">Wrong Rate</option>

                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <button type="button" class="biab_close js-close" data-dismiss="modal" aria-label="Close" style={{ marginLeft: "260px", height: "30px", width: "45px", fontFamily: "inherit", color: "#fff", borderRadius: "6px", backgroundColor: "#0074b1" }} onClick={() => { handleVoid(true) }} >GO</button>
                                </li>


                            </ul>
                        </div>



                        <div class="biab_page-wrapper">
                            <div class="biab_page-holder" style={{ margin: "-22px 6px -46px" }}>
                                <div class="biab_page-container">
                                    <div className="js-scroll-start" style={{ margin: "28px" }} />
                                    <div className="biab_scrollable js-scrollable" style={{ position: 'relative', overflow: 'auto' }}>
                                        <div className="biab_scrollable-content js-scrollable-content" style={{ marginBottom: '107px' }}>
                                            <div className="js-cancel-bets-wrapper">
                                                <div className="biab_cancel-all-bets-wrapper"><span className="js-cancel-all-btn biab_btn biab_cancel-btn biab_hide " style={{}}>Cancel All Unmatched Bets</span><span className="js-confirm-cancel-all-btn biab_btn biab_confirm-btn biab_hide " style={{}}>Confirm Cancellation</span></div>
                                            </div>
                                            <div className="js-loading biab_loading biab_hide"><i className="fa fa-spinner fa-pulse fa-3x fa-fw" /></div>
                                            <React.Fragment>
                                                {refresh === true &&
                                                    <Loader />}
                                                {refresh == false &&
                                                    <div className="biab_table-wrapper js-table-wrapper" s>
                                                        <div className="biab_table-holder" >
                                                            <div className="biab_info-msg js-info-msg biab_mybets-info biab_hide">Please be aware that back odds value for lay bet is approximate and rounded up to 0.01 precision</div>
                                                            <table className="biab_table biab_mybets">
                                                                <colgroup>
                                                                    {betStatus === "Matched" &&
                                                                        <col width={30} />}
                                                                    <col width={85} />
                                                                    <col width={56} />
                                                                    <col width={56} />
                                                                    <col width={66} />
                                                                    <col width={66} />
                                                                    <col width={53} />
                                                                    <col width={60} />
                                                                    <col width={86} />
                                                                    <col width={120} />
                                                                    <col width={90} />
                                                                    <col width={40} />
                                                                </colgroup>
                                                                <thead>
                                                                    <tr>
                                                                        {betStatus === "Matched" &&
                                                                            <th className>
                                                                                <input type="checkbox" class="btn" id="B_logo0" /></th>}

                                                                        <th className>ADM ID</th>
                                                                        <th className>SA ID</th>
                                                                        <th className>SS ID</th>
                                                                        <th className>SUP ID</th>
                                                                        <th className>MA ID</th>
                                                                        <th className>PL ID</th>
                                                                        <th className="biab_odd" >Bet Id</th>
                                                                        <th className>Bet Taken</th>
                                                                        <th className="biab_odd">Ip Address</th>

                                                                        <th className="biab_odd">Market Type </th>
                                                                        <th className="biab_odd">Selection</th>
                                                                        <th className>Type</th>
                                                                        <th className="biab_odd">Odds req.</th>
                                                                        <th className>Stake</th>
                                                                        <th className>liability</th>
                                                                        {betStatus === "Voided" &&
                                                                            <th className>Void Reason</th>}

                                                                    </tr>
                                                                </thead>

                                                                <tbody className="biab_mybets-body js-mybets-body">
                                                                    {currentPosts.map((item, id) => {

                                                                        var matchName;
                                                                        var runner;
                                                                        var odds;
                                                                        var profit = '';
                                                                        var matchtype;
                                                                        if (item.sportId == '4') {
                                                                            matchName = 'CRICKET';
                                                                        } else if (item.sportId == '1') {
                                                                            matchName = 'SOCCER';
                                                                        }
                                                                        else if (item.sportId == '2') {
                                                                            matchName = 'TENNIS';
                                                                        }
                                                                        // console.log(currentPosts,"ompk")
                                                                        var teamame;
                                                                        var status = "", Amount = "", lability = "", Loss = "", Profit = "", onbet = "";
                                                                        var fancy_res = "", Pro_loss = "", mainP_l;
                                                                        var potrntiol_pl, bet_Type_name;
                                                                        if (item.teamName == "A") teamame = runnername1
                                                                        if (item.teamName == "B") teamame = runnername2
                                                                        if (item.teamName == "T") teamame = "DRAW"


                                                                        if (item.type === 'LAGAI') {
                                                                            matchtype = 'BACK';
                                                                        }
                                                                        else if (item.type === 'KHAI') {
                                                                            matchtype = 'LAY';
                                                                        }
                                                                        else {
                                                                            matchtype = item.type;
                                                                        }

                                                                        if (item.betType == 'fancy') {
                                                                            runner = item.runnerName;
                                                                            odds = item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0);
                                                                        }
                                                                        else if (item.betType == 'match') {
                                                                            odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
                                                                            if (item.teamName === 'A') {
                                                                                runner = item.runnerName1;
                                                                            }
                                                                            else if (item.teamName === 'B') {
                                                                                runner = item.runnerName2;
                                                                            }
                                                                            else if (item.teamName === 'T') {
                                                                                runner = 'The Draw';
                                                                            }
                                                                        }

                                                                        if (item.status == 1) { status = "OPEN"; mainP_l = "OPEN" }
                                                                        if (item.status == 2) {
                                                                            status = "SETTLED";
                                                                            mainP_l = (parseFloat(Pro_loss)).toFixed(2);

                                                                        }





                                                                        return (
                                                                            // <tr key={id}>
                                                                            //     <td class=" biab_bet-indicator biab_bet-side-lay biab_fancy-swap " style={{ fontWeight: 'bold' }}>{item.clientId}</td>
                                                                            //     <td class="biab_text-left biab_odd">
                                                                            //         <a class="biab_eventName" href="/customer/sport/event/31084115" style={{ fontWeight: 'bold' }}>{Event_Name}</a>
                                                                            //         <span class="biab_selectionName" style={{ fontWeight: 'bold' }}><strong style={{ display: 'inline' }}>{onbet}</strong></span>
                                                                            //         {/* <a class="biab_selectionName" href="/customer/sport/fancyEvent/31084115/FIRST_INNINGS_FANCY" style={{ fontWeight: 'bold' }}>{item.runnerName}</a> */}
                                                                            //         <span class="biab_groupName" style={{ fontWeight: 'bold' }}>{item.betType}&nbsp;| Bet ID: {item.id} </span>
                                                                            //     </td>
                                                                            //     <td style={{ textTransform: "capitalize", fontWeight: 'bold' }}>{bet_Type_name}</td>
                                                                            //     <td class="biab_odd" style={{ fontWeight: 'bold' }}>{item.rate}</td>
                                                                            //     <td class="" style={{ fontWeight: 'bold' }}>{Amount}</td>
                                                                            //     <td class="biab_odd" style={{ fontWeight: 'bold', color: "red" }}>{(parseFloat(lability)).toFixed(2)}</td>
                                                                            //     <td class="" style={{ fontWeight: 'bold', color: "green" }}>{(parseFloat(potrntiol_pl)).toFixed(2)}</td>
                                                                            //     <td class="biab_odd" style={{ fontWeight: 'bold', color: (fancy_res == "P" ? "green" : "red") }}>{mainP_l}</td>
                                                                            //     <td class="" style={{ fontWeight: 'bold' }}>{item.betTime}</td>
                                                                            //     <td class="biab_odd" style={{ fontWeight: 'bold', textTransform: "capitalize", color: (item.status == 1 ? "red" : "green") }}>{status}</td>
                                                                            // </tr>


                                                                            <tr id="matchRow0" style={{ display: "table-row" }}>
                                                                                {betStatus === "Matched" &&
                                                                                    <td id="agentUserId1" class="align-L">
                                                                                        <input type="checkbox" value={item.id} onChange={() => { storeBetData() }} class="btn" id="B_logo0" /></td>}
                                                                                <td id="agentUserId1" class="align-L">{item.level1}</td>
                                                                                <td id="agentUserId1" class="align-L">{item.level2}</td>
                                                                                <td id="agentUserId2" class="align-L">{item.level3}</td>
                                                                                <td id="agentUserId3" class="align-L">{item.level4}</td>
                                                                                <td id="agentUserId3" class="align-L">{item.level5}</td>
                                                                                <td id="playerId" class="align-L">{item.clientId}</td><td class="align-L">
                                                                                    <a id="betID" href="javascript: void(0);">{item.id}</a></td>
                                                                                <td class="align-L"><span id="betPlaced" class="small-date">{item.betTime}</span></td>
                                                                                <td id="matchSelection" class="align-L"><a>47.11.253.231	</a></td>

                                                                                <td id="matchTitle" class="align-L">{matchName}<img class="fromto" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /><strong>{item.eventName}</strong><img class="fromto" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />{item.marketName}</td>
                                                                                <td id="matchSelection" class="align-L"><a>{runner}</a></td><td class="align-C"><span id="matchType" class="back">{matchtype}</span></td><td id="matchOddsReq">{item.rate}</td><td id="matchStake">{item.amount}</td>
                                                                                <td id="liability">
                                                                                    <span class="red">-</span>
                                                                                </td>
                                                                                {betStatus === "Voided" &&
                                                                                    <td id="matchSelection" class="align-L"><span class="red" style={{ color: 'red' }}>{item.VoidReason}</span></td>}
                                                                            </tr>

                                                                        )
                                                                    })}


                                                                </tbody>
                                                            </table>
                                                            <div className="biab_table-widget" style={{ display: 'none' }}>
                                                                <div className="biab_btns-holder"><a href="#" className="biab_widget-btn biab_btn-prev js-prev disabled">Previous</a><a href="#" className="biab_widget-btn biab_btn-next js-next disabled">Next</a></div>
                                                                <div className="biab_info"><span className="biab_counter js-counter">Page 1 of 1</span>
                                                                    <div className="biab_view-per-page js-view-per-page" /></div>
                                                            </div>
                                                        </div>


                                                        {betmonitorData.length > 0 && <div className="pages" style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
                                                            <Pagination prevPageText='PREV'
                                                                pageRangeDisplayed={3}
                                                                activePage={currentPage}
                                                                nextPageText='NEXT'
                                                                totalItemsCount={betmonitorData.length}
                                                                onChange={handlePageChange}
                                                                itemsCountPerPage={postsPerPage}
                                                                hideFirstLastPages
                                                            />
                                                        </div>}
                                                    </div>}
                                            </React.Fragment>


                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>

        </React.Fragment>
    )
}



{/* <li>
                                    <div style={{ height: '30px', position: 'absolute', zIndex: '99', marginLeft: '290px' }}>
                                        <p style={{ color: 'white', fontSize: '8px', marginTop: '-8px', position: 'absolute', zIndex: '99', color: 'skyblue' }}>Select Result</p>
                                        <select class="input_user_search " style={{ height: '30px', width: '100px', backgroundColor: 'transparent', color: 'gray', fontSize: '12px' }} >
                                            <option value="0">Select Result</option>
                                            <option value="1">All</option>
                                            <option value="2">Won</option>
                                            <option value="3">Lost</option>
                                            <option value="4">Unsettled</option>
                                            <option value="4">Rollback</option>
                                            <option value="4">Voided</option>
                                        </select>
                                    </div>
                                </li> */}